﻿@mixin icon-builder($icon, $width: 28px, $height: 28px) {
	.#{$icon} {
		display: inline-block;
		position: relative;
		background: url(/assets/images/#{$icon}.png) no-repeat 50%;
		background-size: contain;
		width: $width;
		height: $height;
		vertical-align: middle;
	}
}

@include icon-builder(icon_account, 24px, 24px);
@include icon-builder(icon_connections, 24px, 24px);
@include icon-builder(icon_locations, 24px, 24px);
@include icon-builder(icon_myprofile, 24px, 24px);
@include icon-builder(icon_settings, 24px, 24px);
@include icon-builder(icon_tools, 24px, 24px);
@include icon-builder(icon_history, 24px, 24px);
@include icon-builder(icon_note_types, 24px, 24px);
@include icon-builder(icon_notes, 24px, 24px);
@include icon-builder(icon_discounts, 24px, 24px);
@include icon-builder(login_logo, 128px, 125px);
@include icon-builder(icon_trial_users, 72px, 72px);
@include icon-builder(icon_trial_days, 72px, 72px);
@include icon-builder(img_connector_for, 112px, 66px);
@include icon-builder(img_connector_or, 112px, 66px);
@include icon-builder(icon_users, 100px, 100px);
@include icon-builder(icon_months, 100px, 100px);
@include icon-builder(icon_total, 100px, 100px);
@include icon-builder(icon_web_portal, 100px, 100px);
@include icon-builder(icon_help, 72px, 72px);
@include icon-builder(icon_faqs, 72px, 72px);
@include icon-builder(icon_cross_grey, 28px, 28px);
@include icon-builder(img_connecting_arrow, 28px, 100%);
