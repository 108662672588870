﻿.trial-register {
    h2 {
        font-size: 32px;
        margin: 30px 0;
    }

    .information {
        &.with-line {
            border-right: 1px solid #e9e9e9;
        }

        p {
            font-size: 18px;
            font-family: 'Roboto Slab', serif;
            margin: 30px 0;
        }

        ul {
            padding: 0;
            list-style: none;

            > li {
                background: url(/assets/images/icon_tick.png) no-repeat 0 0;
                padding-left: 28px;
                line-height: 18px;
                margin-bottom: 20px;
                color: #AAAAAA;
                font-size: 16px;
            }
        }

        .diagram {
            text-align: center;
            background: #fff;
            padding: 30px 50px;
            font-family: 'Roboto Slab', serif;

            .img_connector_for {
                margin: 85px 0 0;
            }

            .number {
                margin: 10px 0 0;
                font-size: 72px;
                color: #ffca05;
                line-height: 1;
            }

            .text {
                margin: 0;
                font-size: 24px;
                color: #3F3F51;
            }
        }
    }

    .form {
        p {
            margin: 30px 0;
            font-size: 16px;
            color: #aaa;

            span {
                float: right;
                font-size: 12px;
            }
        }

        hr {
            margin: 40px 0;
        }
    }
}

.widget {
    width: 242px;
    height: 64px;
    border: 1px solid #eee;
    margin: auto;

    button {
        width: 62px;
        height: 62px;
        border: 0;
        float: left;
        font-size: 0;

        &:last-of-type {
            background: url(/assets/images/btn_plus.png);
        }

        &:first-of-type {
            background: url(/assets/images/btn_minus.png);
        }
    }

    input[readonly] {
        float: left;
        width: 116px;
        height: 62px;
        text-align: center;
        font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
        border-top: 0;
        border-bottom: 0;
        color: #ffca05;
        font-size: 42px;
        background: #fff;
    }
}

.steps {
    list-style: none;
    text-align: center;
    margin: 70px 0 100px;
    padding: 0;
    color: #bfbfbf;
    font-size: 16px;

    li {
        float: left;
        width: (100%/3);
        position: relative;

        &:after {
            content: '';
            background: url(/assets/images/icon_step_circle.png);
            display: block;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 50%;
            bottom: -37px;
            transform: translate(-50%, 0);
        }

        &.active {
            color: #71717c;

            &:after {
                background: url(/assets/images/icon_step_circle_selected.png) 50% no-repeat, url(/assets/images/icon_step_circle.png);
            }
        }

        &:before {
            content: '';
            display: block;
            height: 4px;
            background: #e9e9e9;
            position: absolute;
            bottom: -27px;
        }

        &:nth-of-type(1) {
            &:before {
                left: 50%;
                right: 0;
            }
        }

        &:nth-of-type(2) {

            &:before {
                left: 0;
                right: 0;
            }
        }

        &:nth-of-type(3) {
            &:before {
                left: 0;
                right: 50%;
            }
        }
    }

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

.total {
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0;

    p {
        margin-bottom: 0;
        font-family: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 24px;

        @media (min-width: $screen-sm-min) {
            font-size: 20px;
        }
        
        b {
            font-weight: 400;
        }

        span {
            display: block;
            color: #ffca05;
            font-size: 64px;

            @media (min-width: $screen-sm-min) {
                font-size: 50px;
            }
        }
    }

    @media(min-width: $screen-sm) {
        &.row {
            > * {
                position: relative;
            }
        }
    }

    .plus,
    .equals {
        @media (min-width: $screen-sm-min) {
            width: calc((100% - 84%) / 2);
            padding: 0;
        }

        p {
            span {
                @media (min-width: $screen-sm-min) {
                    padding-top: 120px;
                }
            }
        }
    }

    > [class^="col-sm-2"],
    .panel [class^="col"] {
        padding-top: 20px;
        padding-right: 0;
        padding-bottom: 20px;
        padding-left: 0;
    }

    .panel {
        background-color: #efefef;

        @media (min-width: $screen-sm-min) {
            display: flex;
            flex-direction: row;
        }

        .row {
            @media (min-width: $screen-sm-min) {
                flex: 1;
            }
        }
    }
}
