﻿@mixin noContentBefore() {
    &:before {
        content: "" !important;
    }
}

.container-fluid {
    position: relative;
}

.k-menu .k-item > .k-link > .k-icon {
    @include noContentBefore();
    margin-right: 10px;

    &.k-i-sort-asc-sm {
        background-position: -112px -240px;
    }

    &.k-i-sort-desc-sm {
        background-position: -112px -256px;
    }

    &.k-i-columns {
        background-position: -112px -304px;
    }

    &.k-i-filter {
        background-position: -32px -80px;
    }

    &.k-i-arrow-60-right {
        background-position: 0 -16px;
    }

    &.k-i-lock {
        background-position: -63px 0px;
    }

    &.k-i-unlock {
        background-position: -63px -15px;
    }
}

.k-icon {
    &.k-i-sort-asc-sm,
    &.k-i-sort-desc-sm,
    &.k-i-arrow-60-up,
    &.k-i-arrow-60-right,
    &.k-i-arrow-60-down,
    &.k-i-arrow-60-left,
    &.k-i-calendar {
        @include noContentBefore();
    }

    &.k-i-sort-asc-sm {
        background-position: -160px -161px;
    }

    &.k-i-sort-desc-sm {
        background-position: -160px -140px;
    }

    &.k-i-arrow-60-up {
        background-position: 0 0;
    }

    &.k-i-arrow-60-right {
        background-position: 0 -16px;
    }

    &.k-i-arrow-60-down {
        background-position: 0 -32px;
    }

    &.k-i-arrow-60-left {
        background-position: 0 -48px;
    }

    &.k-i-calendar {
        background-position: -32px -176px;
    }
}



.k-button, .k-window-action {
    .k-icon, &:hover .k-icon {
        background-image: url("/assets/images/sprite-metro-black.png");

        &.k-i-more-vertical {
            background-position: 0 -32px;
            @include noContentBefore();
        }

        &.k-update {
            background-position: -160px -97px;
        }

        &.k-pin {
            background-position: -160px -272px;
        }

        &.k-i-cancel,
        &.k-cancel {
            @include noContentBefore();
            background-position: -32px -17px;
        }

        &.k-delete {
            background-size: auto auto;
            background-image: url(/assets/images/icon_delete_grey_sm.png);
            background-position: 50%;
            width: 18px;
            height: 18px;
        }
    }

    &.icon-k-delete, &.icon-k-delete span {
        opacity: 1;
        vertical-align: text-bottom;
        margin: 0 5px 0 0;
        display: block;
        background-size: auto auto;
        background-image: url(/assets/images/icon_delete_grey_sm.png);
        background-position: 50%;
        width: 18px;
        height: 18px;
    }

    &.icon-k-copy, &.icon-k-copy span {
        opacity: 1;
        vertical-align: text-bottom;
        margin: 0 5px 0 0;
        display: block;
        background-size: auto auto;
        background-image: url("/assets/images/icon_copy_sm.png");
        background-position: 50%;
        width: 18px;
        height: 18px;
    }
}

.smt-k-ui {
    /*overide kendo theme css*/
    .k-widget {
        border: none;
        background-color: transparent;
    }

    .k-grid {
        .k-link {
            text-align: center;
        }

        tr {
            &:hover {
                background-color: rgba(0, 0, 0, .05);
            }
        }
    }

    .k-header-column-menu {
        &.k-state-active {
            background-color: transparent;
        }
    }

    .k-button {
        border-color: rgb(66,66,81);
        background-color: rgb(66,66,81);
        color: rgb(255, 255, 255);

        &:hover, &:focus, &:active, &:focus:not(.l-stat-disabled):not([disabled]) {
            border-color: rgb(27,27,33);
            background-color: rgb(27,27,33);
            box-shadow: none;
            text-decoration: none;

            &.k-grid-delete, &.k-grid-Delete, &.k-grid-edit, &.k-grid-Copy {
                background-color: transparent;
                border: none;
            }
        }

        &.k-button-toggle {
            input {
                display: none;
            }
        }

        .k-icon, &:hover .k-icon {
            opacity: 1;
            background-position: 50%;
            background-size: auto auto;
            vertical-align: text-bottom;
            margin: 0 5px 0 0;
            width: 20px;
            height: 16px;
            @include noContentBefore();


            &.smt-k-upload {
                background-image: url("/assets/images/btn_icon_import_white.png");
            }

            &.k-i-add,
            &.k-add {
                background-image: url("/assets/images/btn_icon_add_white.png");
            }

            &.k-i-excel {
                background-image: url("/assets/images/btn_icon_export_white.png");
                @include noContentBefore();
            }

            &.smt-k-masstransfer {
                background-position: -177px -160px;
            }

            &.smt-k-save {
                background-image: url("/assets/images/icon_save_white.png");
            }

            &.smt-k-load {
                background-position: -31px -111px;
            }

            &.smt-k-active {
                background-position: -177px -96px;
            }

            &.smt-k-inactive {
                background-position: -177px -79px;
            }

            &.smt-k-map {
                background-position: -158px -608px;
            }

            &.k-edit, &.k-i-edit {
                background-image: url("/assets/images/icon_edit_grey.png");
            }

            &.k-i-close {
                background-position: -32px -16px;
            }
        }

        &.k-grid-AddNote,
        &.k-grid-edit, &.k-grid-delete, &.k-grid-Delete, &.k-grid-Copy {
            &,
            &:hover,
            &:active,
            &:focus {
                background-color: transparent;
                border: none;
                color: rgb(66,66,81);
                font-size: 0;
                width: 18px;
                height: 18px;
                display: inline-block;
                margin: 0 5px;
                min-width: auto;
                vertical-align: middle;
                padding: 0;

                .k-icon {
                    display: block;
                }
            }
        }

        &.k-grid-AddNote {
            background-image: url("/assets/images/icon_add_note.png");
        }

        &.k-grid-AddNote:active, &.k-grid-AddNote:focus {
            background-color: transparent !important;
            border-color: transparent !important;
            outline: none !important;
        }
    }




    .k-grid-header .k-header {
        border-color: rgb(255,255,255);

        .k-icon, &:hover .k-icon {
            background-image: url("/assets/images/sprite-metro-black.png");
            @include noContentBefore();

            &.k-i-more-vertical {
                background-position: 0 -32px;
            }
        }
    }


    .k-header {
        padding-left: 0;

        .k-button {
            &:first-child {
                margin-left: 0;
            }

            text-transform: capitalize;
        }
    }

    .k-grid-header .k-header {
        background-color: rgb(66,66,81);
        color: rgb(255,255,255);
        text-align: center;

        &:hover {
            background-color: rgb(27,27,33);
        }

        .k-link {
            .k-i-arrow-n {
                background-position: -160px -161px;
            }

            .k-i-arrow-s {
                background-position: -160px -140px;
            }
        }

        .k-link:hover .k-i-arrow-n {
            background-position: -160px -161px;
        }

        .k-link:hover .k-i-arrow-s {
            background-position: -160px -140px;
        }

        &.k-state-focused {
            box-shadow: none;
        }
    }

    .k-grid-header .k-header .k-link {
        color: rgb(255,255,255);
    }

    .k-pager-wrap {
        .k-dropdown {
            width: 5em;
        }

        .k-link {
            border: none;

            .k-icon {
                background-image: url("/assets/images/sprite.png");
            }

            &:hover {
                color: rgb(255,202,5);
            }

            &.k-state-disabled {
                .k-icon {
                    opacity: 0.5;
                }
            }
        }

        .k-state-selected {
            background-color: rgb(255,202,5);
            border: none;
            border-radius: 0;
        }
    }

    .k-grouping-header {
        padding-left: 0;

        .k-icon {
            background-image: url("/assets/images/sprite.png");

            &.k-group-delete {
                background-position: -32px -16px;
            }
        }
    }

    .k-grid-content {

        table tr td {
            height: 2em;
        }
    }

    .k-grid {
        td {
            white-space: nowrap;
        }
    }
}

.k-reset{
    .k-icon.k-i-collapse{
        background-image: none;
    }
}

div.k-window {
    border: none;
    box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.3);
    border-width: 0;

    &.k-state-focused {
        border-color: transparent;
        border: 0;
    }

    .k-window-content {
        max-height: 800px;
        overflow: auto;
    }

    .k-window-title {
        line-height: 40px;
        left: 25px;
        right: 25px;
    }

    .k-icon.k-i-close {
        background-size: auto auto;
        background-image: url(/assets/images/icon_cross_grey.png);
        background-position: 50%;
        width: 28px;
        height: 28px;

        @include noContentBefore();
    }

    .k-state-hover .k-icon.k-i-close {
        background-image: url(/assets/images/icon_cross_white.png);
        background-position: 50%;
        background-color: #ffd538;
        @include noContentBefore();
    }

    .k-window-titlebar {
        background-color: rgb(66,66,81);
        color: rgb(255,255,255);
        border: none;
        margin-bottom: .25em;
        height: 40px;
        line-height: 40px;

        .k-window-actions {
            padding-top: 0;
            width: 28px;
            height: 28px;
            top: 6px;
            right: 20px;

            .k-window-action {
                padding: 0;
                width: 28px;
                height: 28px;
                border: none;

                &.k-state-hover {
                    border: none;
                    color: #333333;
                    background-color: #ffd538;
                    border-color: #ffd742;
                    border-radius: 0;
                }
            }
        }
    }

    .k-edit-form-container {
        width: 100%;
    }

    .k-edit-buttons {
        text-align: center;
        margin-bottom: 1.5em;
    }

    .image-container {
        position: relative;
    }

    .form-control {
        height: auto;
        width: 100%;
    }

    .k-combobox {
        width: 100%;

        input {
            padding: 6px 12px;
        }
    }

    .form-horizontal .form-group {
        margin-left: 0;
        margin-right: 0;
    }

    .image-btn-container {
        border-top: none;
    }

    .container-fluid {
        padding: 15px;
        position: relative;

        * {
            box-sizing: border-box;
        }
    }

    .rental-details {
        display: none;
    }
}

.image-controller-container {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 95%;
    margin: .5em 1em;
    background-color: #e9e9e9;

    &.active {
        max-height: 1000px;
    }

    .image-controller {
        padding: .5em;
    }

    #fileUpload {
        display: inline-block;

        input {
            width: 100%;
        }
    }

    #image-counter {
        padding: .5em 0;
    }

    .image-loader-header {
        padding: 1.5em 1.5em .5em;
        width: 95%;
        display: block;
        clear: both;

        h2 {
            font-family: Arial;
            font-size: 1.3em;
            font-weight: normal;
            float: left;
            margin-top: 0;
            padding-top: .25em;
        }

        #cancel {
            float: right;
        }
    }

    p {
        padding: 0 1.5em 1em;
    }

    .tool-images {
        border-bottom: 1px solid rgb(198,198,198);
        padding-bottom: 2em;
        margin: 0 1.47em;

        .k-icon {
            &.tool-lbl-edit {
                background-image: url("/assets/images/icon_edit_grey.png");
            }
        }
    }
}

.details-controller {
    .form-col {
        padding: 0;
    }

    .control-label {
        padding-left: 0;
        padding-right: 0;
    }

    .k-numerictextbox {
        width: 100%;
    }

    .edit-images {
        display: block;
    }
}

@media screen and (max-width: $screen-xs-max) {
    .k-grid-toolbar {
        padding-left: 0;

        .k-button {
            width: 100%;
            margin-bottom: .5em;
        }
    }
}


div.k-window .k-confirm-submition-container {
    display: none;
    text-align: center;
    width: 100%;
    padding-top: 10em;
}

//connections update window

.user-type {
    max-height: 0;
    transition: all ease .5s;
    overflow: hidden;

    &.active {
        max-height: 100px;
        overflow: visible;
    }
}

#toolBoxErrorWindow {
    display: none;
}

#deleteConfirmationWindow,
#copyConfirmationWindow {
    display: none;

    p {
        text-align: center;
        margin: 1em 0;
    }

    .delete-content,
    .copy-content {
        max-height: 0;
        transition: all ease .5s;
        overflow: hidden;

        &.active {
            max-height: 1000px;
        }

        .deleteText, .toolsLoanText {
            display: none;
        }
    }
}

//overide table width
.k-grid-header table, .k-grid-content table{
    width: 100%!important;
}