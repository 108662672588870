﻿.toggle {
	display: inline-block;
	height: 30px;
	vertical-align: top;
	/*margin-left: 30px;*/

	input[type="checkbox"] {
		max-height: 0;
		max-width: 0;
		opacity: 0;

		& + input[type="hidden"] {
			& + label {
				display: inline-block;
				position: relative;
				box-shadow: inset 0 0 0px 1px #d5d5d5;
				text-indent: -5000px;
				height: 30px;
				width: 50px;
				border-radius: 15px;
				cursor: pointer;

				&:before {
					content: '';
					position: absolute;
					display: block;
					height: 30px;
					width: 30px;
					top: 0;
					left: 0;
					border-radius: 15px;
					background: rgba(19,191,17,0);
					transition: .25s ease-in-out;
				}

				&:after {
					content: '';
					position: absolute;
					display: block;
					height: 30px;
					width: 30px;
					top: 0;
					left: 0px;
					border-radius: 15px;
					background: white;
					box-shadow: inset 0 0 0 1px rgba(0,0,0,.2), 0 2px 4px rgba(0,0,0,.2);
					transition: .25s ease-in-out;
				}
			}
		}

			

		&:checked {
			& + input[type="hidden"] {
				& + label {
					&:before {
						width: 50px;
						background: $navbar-default-bg;
					}

					&:after {
						left: 20px;
						box-shadow: inset 0 0 0 1px rgba($navbar-default-bg, 1), 0 2px 4px rgba(#000, 0.2);
					}
				}
			}
		}
	}
}