﻿.k-button {
	@extend .btn;
	@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
	text-transform: none;
	box-shadow: none !important;
	font-weight: bold;
	font-size: $font-size-small;
}

.k-primary,
.k-overflow-container .k-primary {
  color: #fafafa;
  border-color: #ebb900;
  background-image: none;
  background-position: 50% 50%;
  background-color: #ffca05;
  box-shadow: none;
}

.k-primary:focus,
.k-primary.k-state-focused {
  color: #fafafa;
  border-color: #eef8fc;
  background-image: none;
  box-shadow: 0 0 8px 0 #cbebf5;
}
.k-primary:hover {
  color: #fafafa;
  border-color: #ad8900;
  background-image: none;
  background-color: #dbad00;
  box-shadow: none;
}
.k-primary:focus:active:not(.k-state-disabled):not([disabled]),
.k-primary:focus:not(.k-state-disabled):not([disabled]) {
  box-shadow: 0 0 8px 0 #cbebf5;
}
.k-primary:active, .k-primary:focus, .k-primary:hover, .k-primary:active:hover {
  color: #fafafa;
  border-color: #ad8900;
  background-image: none;
  background-color: #dbad00;
  box-shadow: inset 0 0 8px 0 #ffd847;
}
.k-primary.k-state-disabled,
.k-state-disabled .k-primary,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:hover,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:active,
.k-primary.k-state-disabled:active {
  color: #fafafa;
  border-color: #ffd94c;
  background-color: #ffdb57;
  background-image: none;
  box-shadow: none;
}

div.k-window {
    .k-window-titlebar {
        background-color: $brand-color;
    }
}

.k-grid-header {
    .k-header {
        .smt-k-ui & {
            background-color: $brand-color;

            &:hover {
                background-color: darken($brand-color, 5%);
            }
        }
    }
}

.k-grid {
    tr:hover  {
        .smt-k-ui & {
            background-color: lighten($brand-color, 60%);
        }
    }
}

.k-window-content {
    a:not(.k-button) {
        color: $brand-color;
        text-decoration: underline;
    }
}

.k-tooltip {
  text-align: left;
}

.lookup-message {
    padding-top: 7px;
}

.badge {
    position: relative;
    top: -2px;
    margin-left: 5px;
    padding: 3px 5px 4px 5px;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    background-color: #1d4f91;
    border-radius: 4px;
}