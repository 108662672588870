﻿.tool-map {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}

    .tool-map__map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .tool-map__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 9px 3px;
        font-size: 40px;
        line-height: 14px;
        text-align: center;
        background-color: #fff;
        z-index: 1;
        cursor: pointer;

        .k-icon {
            background-image: none;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }