﻿.footer {
	margin-top: $navbar-margin-bottom;
	background-color: $brand-color;
	color: white;
	text-align: center;
	font-size: $font-size-small;

    .login & {
        color: black;
        background-color: $brand-yellow;
    }

	p {
		line-height: 40px;
		margin: 0;
	}
}