// Anything less than 1024
@media only screen 
  and (max-device-width: 1024px) 
  and (min-device-width: 597px) {

  	// Navigation
  	.nav-tabs {
  		> li {
  			width: 20%;
  			font-size: 75%;
  		}
  	}

}

@media only screen 
  and (max-device-width: 597px) 
  and (min-device-width: 410px) {

  	// Navigation
  	.nav-tabs {
  		> li {
  			width: 50%;
  			font-size: 75%;
  		}
  	}

}

@media only screen 
  and (max-device-width: 409px) {

  	// Navigation
  	.nav-tabs {
  		> li {
  			width: 100%;
  			font-size: 75%;
  		}
  	}

}