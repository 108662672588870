﻿/*
 * _custom-variables.scss
 * Define any custom variables here
 */

$footer-bg: #424251;
$footer-colour: #999;

$panel-bg: #fafafa;
$panel-border: #e9e9e9;

$grid-alt-row: #f6f6f6;

$error-text: #a94442;

$brand-color: #1d4f91; //Pantone 7686C
$brand-success: #6cc24a; //Pantone 360C
$brand-error: #ab2328; //Pantone 7621C

$brand-yellow: #ffca05;