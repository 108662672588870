﻿.login {
	background-color: $panel-bg;
	border-top: 50px solid $panel-border;
    padding-bottom:60px;

	.intro-text {
		font-size: $font-size-large;
	}

	.forgotten-password-link {
		font-size: $font-size-small;
	}

	.reset-password-form-group {
		margin-top: 30px;
	}

	.alert {
		margin: ($padding-large-horizontal * 2) 0;
	}

	.login_logo {
		margin-top: -30px;
	}

	&.with-arrow-down {
		padding-bottom: 32px;
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 64px;
			height: 64px;
			background: url(/assets/images/img_arrow_down.png) no-repeat 0 0;
			margin-left: -32px;
			position: absolute;
			left: 50%;
			bottom: -32px;
		}
	}
}