﻿@media (min-width: 480px) and (max-width: 1199px) {
    .sd_col-md-6 {
        width: 50%;
        float: left;
    }
}

@media (min-width: 1199px) and (max-width: 1327px) {
    .sd_col-lm-12 {
        width: 100%
    }

    .sd_col-lm-8 {
        width: 66.66%
    }
}

@media(min-width: $screen-sm-min) {
    .pull-sm-right {
        float: right;
    }
}


.billing-information dl {

    dt {
        text-align: left;
        color: gray;
        white-space: normal;
        fontl-weight: normal;
    }

    dd {
        text-align: right;
        color: #424251;
    }
}

.billing-action {
    margin-top: 40px;

    #cancel-plan {
        margin-top: 12px;
        color: #CCCCCC;
    }

    @media (max-width:480px) {
        .btn {
            width: 100%;
            margin-bottom: 10px;
        }

        #cancel-plan {
            float: none;
            text-align: center;
            width: 100%;
        }
    }
}

.modal-container {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    padding: 10px 10px 0;

    &:after {
        vertical-align: middle;
        display: inline-block;
        height: 100%;
        margin-left: -.05em;
        content: "";
    }

    .modal {
        vertical-align: middle;
        display: inline-block;
        position: relative;
        background: #ffffff;
        width: 95%;
        max-width: 700px;
        z-index: 101;
        box-sizing: border-box;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);

        .modal-header {
            background-color: #3f3f51;
            height: 17%;
            color: white;
            padding: 17px;

            .modal-header-close {
                position: absolute;
                top: 4%;
                right: 2%;
            }
        }

        .container {
            width: inherit;
            height: inherit;
            padding: 0 15px;

            h3 {
                margin-bottom: 1.5em;
            }

            .alert {
                display: none;
                margin-bottom: 0;
                text-align: left;
            }

            .alert-danger {
                text-align: center;
                display: block;
            }
        }
    }
}

.button-container {
    margin-bottom: 20px;

    .k-primary {
        color: #000000;
    }

    @media (max-width: $screen-lg-min) {
        .k-primary {
            margin-bottom: 10px;
        }

        @media (max-width: $screen-sm-min) {
            .btn {
                width: 100%;
                min-width: 100%;
            }
        }
    }
}

.modal-overlay {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    background-color: black;
    z-index: 100;
    display: none;
}

.divider {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 10%;
    border-right: 1px solid #E9E9E9;

    .edit-plan-container & {
        left: 30%;
    }

    .upgrade-plan-container & {
        left: 55%;
    }

    &.arrow {
        &:after {
            top: 40%;
            left: -28px;
            background: url(/assets/images/img_connecting_arrow.png) no-repeat;
            width: 60px;
            height: 61px;
            display: block;
            content: '';
            position: absolute;
        }
    }
}




@media (max-width: 1199px) {
    .divider {
        margin-top: 40px;
        margin-bottom: 40px;

        &.arrow {
            width: 100%;
            border-bottom: 1px solid #E9E9E9;
            position: relative;
            left: 0;

            &:after {
                position: absolute;
                left: 45%;
                top: -28px;
                -webkit-transform: rotate(90deg);
            }
        }
    }
}

.billing-container {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;

    .icon_months, .icon_users, .icon_total {
        width: 110px;
        z-index: 100;
        /*background-color: #fafafa;*/
    }

    h2 {
        margin-top: 0;
    }

    .widget {
        margin: 0;
    }

    .form-group {
        p {
            text-indent: 0.3em;
        }
    }

    .total.row {
        > *:last-of-type:before {
            display: none;
        }

        > *:first-of-type:before {
            display: none;
        }
    }

    @media(min-width: $screen-sm-min) {
        .month_col {
            &:before {
                content: '';
                display: block;
                height: 1px;
                background: #e9e9e9;
                position: absolute;
                top: 50px;
                right: 70%;
                left: -60px;
            }

            &:after {
                content: '';
                display: none;
                height: 1px;
                background: #e9e9e9;
                position: absolute;
                top: 50px;
                left: 70%;
                right: -60px;
            }
        }

        .month_col.month_center {
            &:after {
                display: block;
            }
        }
    }
}

.col-2-form-divider {
    position: relative;

    .divider {
        top: 10%;
        bottom: 7%;

        @media (min-width: $screen-lg-min) {
            top: 6%;
            bottom: 32%;
            margin-top: 0;
        }

        @media (max-width: 1316px) {
            top: 6%;
            bottom: 28%;
            margin-top: 0;
        }

        @media (min-width: $screen-xs-min) and (max-width: 991px) {
            bottom: 23%;
        }

        @media(max-width: 767px) {
            width: 90%;
            border-bottom: 1px solid #E9E9E9;
            position: relative;
            left: 5%;
            margin-bottom: 40px;
            margin-top: 30px;
        }
    }

    @media(min-width: $screen-sm-min) {
        padding-bottom: 90px;

        .address-row-2 {
            position: absolute;
            bottom: 0;
        }
    }
}

.billing {
    .login {
        .payment-details, .plan-details {
            .billing-information {
                padding: 20px 0;

                dl {
                    dt {
                        @media(max-width: 480px) {
                            width: 100%
                        }

                        @media(min-width: $screen-xs-min) {
                            width: 35%;
                        }
                    }

                    dd {
                        @media(max-width: 480px) {
                            width: 100%
                        }

                        @media(min-width: $screen-xs-min) {
                            width: 63%;
                            margin-left: 0;
                        }
                    }

                    dt, dd {
                        display: inline-block;
                        margin-top: 20px;
                        float: none;
                        vertical-align: top;
                    }
                }
            }
        }

        .payment-details {
            dl {
                dd {
                    text-align: left;
                }
            }
        }

        .plan-details {
            dl {
                dd {
                    @media(max-width: 480px) {
                        text-align: left;
                    }

                    @media(min-width: $screen-xs-min) {
                        text-align: right;
                    }
                }
            }
        }
    }

    .row.form-header-content {
        h2 {
            float: left;

            @media(max-width: 440px) {
                float: none;
            }
        }

        p {
            line-height: 2em;

            @media(min-width: $screen-md-min) {
                line-height: 3em;
            }

            @media(max-width: 440px) {
                float: none !important;
            }
        }
    }
}

.plan-user-control {
    margin-top: 40px;
}
