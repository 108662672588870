﻿label {
    font-weight: normal;
}

.asterisk {
    color: $navbar-default-bg;
}

.strike {
    text-decoration: line-through;
}

.form-group .question-sign {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-size: 32px;
    background-image: url('/assets/images/icon_help.png');
}

.form-group .popover {
    max-width: initial !important;
    width: 500px !important;

    h4 {
        font-size: 14px;
    }

    li {
        font-size: 12px;
    }

    &.left > .arrow {
        margin-top: -20px;
    }
}

#flexiHeight {
	background-color: $panel-bg;
	margin-left: ($grid-gutter-width / 2);
	margin-right: ($grid-gutter-width / 2);
}
