﻿
    .tool-img {
        width:100px; 
        height:100px;
        max-height:100px;
        max-width:100px;
    }

    .tool-img-wrap {
        position: relative;
        display: inline-block;
        margin: 0 5px;
    }

    .tool-img-delete {
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 1;
    }

    .tool-lbl-edit {
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 1;
        background-size: auto auto;
        background-image: url(/assets/images/icon_edit_grey_light.png);
        background-position: 50%;
        width: 26px;
        height: 26px;
        margin: 0;
    }

    .tool-lbl-edit:hover {
        background-image: url(/assets/images/icon_edit_white.png);
    }

    .tool-input-edit {
        width: 0.1px;
	    height: 0.1px;
	    opacity: 0;
	    overflow: hidden;
	    position: absolute;
	    z-index: -1;
    }
