﻿.navbar-brand {
	padding-top: 18px;
	padding-bottom: 18px;
}
.navbar-default{
    background-color: rgb(255,255,255);
    border: none;
    .navbar-text{
        color: $btn-primary-bg;
    }
}