﻿/// <reference path="..\..\node_modules\bootstrap-sass\assets\stylesheets\bootstrap\_variables.scss" />

.product-options {
    margin-top: 80px;
    margin-bottom: 80px;

    .row {
        @media (min-width: $screen-lg-min) {
            display: flex;
            flex-direction: row;
        }

        [class*="col"] {
            @media (min-width: $screen-lg-min) {
                display: flex;
                flex-direction: column;
                float: none;
            }
        }
    }

    .or {
        height: 424px;
        position: relative;

        @media (min-width: $screen-lg-min) {
            width: calc((100% - 75%) / 2);
        }

        .img_connector_or {
            position: absolute;
            left: 50%;
            height: (66px + 70px);
            top: 50%;
            transform: translate(-50%, -50%);
            border-top: 35px solid #fff;
            border-bottom: 35px solid #fff;
            z-index: 10;
        }

        &:before {
            width: 0;
            border-left: 1px solid #efefef;
            position: absolute;
            content: '';
            left: 50%;
            top: 25px;
            bottom: 50%;
        }

        &:after {
            width: 0;
            border-left: 1px solid #efefef;
            position: absolute;
            content: '';
            left: 50%;
            top: 50%;
            bottom: 25px;
        }
    }

    .product-option {
        text-align: center;

        h3 {
            background: #ffca05;
            padding: 0;
            margin: 0;
            line-height: 80px;
        }

        .no-users {
            padding: 20px 50px;
            border-left: 1px solid #efefef;
            border-right: 1px solid #efefef;
            position: relative;

            p {
                font-weight: bold;
                font-family: "Roboto Slab";
                font-size: 24px;

                span {
                    display: block;
                    color: #ffca05;
                    font-size: 74px;
                    font-weight: normal;
                }
            }

            &:after {
                content: '';
                width: 0;
                height: 0;
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -10px;
                border: 10px solid;
                border-color: #fff transparent transparent transparent;
            }
        }

        .product-footer {
            border: 1px solid #efefef;
            margin-bottom: 20px;
            padding: 40px 50px;

            @media (min-width: $screen-lg-min) {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: center;
            }

            p {
                margin-bottom: 40px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                strong {
                    font-size: 18px;
                }
            }
        }

        .btn {
            align-self: center;
            text-transform: none;
        }
    }
}

.thankyou {

    .confirmation {
        margin: 25px 0;
    }

    .btn {
        margin-top: 15px;
    }
}

.help {
    margin-bottom: 40px;

    .text {
        margin: 40px 0;
    }

    .links {
        height: 120px;
        text-align: center;

        a {
            &:hover {
                text-decoration: none;
            }

            .icon {
                margin-bottom: 5px;
            }
        }

        .or {
            height: 100%;
            position: relative;

            &:before {
                border-left: 1px solid #efefef;
                content: '';
                height: 100%;
                left: 50%;
                position: absolute;
                width: 0;
            }
        }
    }
}

.discount {
    .applied {
        display: none;
    }

    .icon_cross_grey:hover {
        cursor: pointer;
    }

    .errors {
        color: $error-text;
        font-size: 12px;
    }
}

@media only screen and (min-width: $screen-lg) {
    .discount {
        .apply {
            margin-top: 24px;
        }

        .applied {
            padding-top: 3px;
        }

        .icon_cross_grey {
            position: relative;
            top: -1px;
        }

        .errors {
            margin-top: 32px;
        }
    }
}
