﻿.alert {
	> p,
	> ul {
		font-size: $font-size-small;
	}

	> ul {
		list-style: none;
		padding: 0;
		margin-top: $padding-small-horizontal;
	}
}