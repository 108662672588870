﻿.my-profile {
	border-top: 0;
	padding: ($grid-gutter-width * 2) 0;

	.change-password {
		margin-bottom: 20px;
	}

	.show-terms-conditions {
		line-height: 30px;
		height: 30px;
		margin-bottom: 30px;
	}

	.form-group-lg {
		textarea.form-control {
			height: 145px;
		}
	}
}