﻿.nav-tabs {
    background-color: $panel-border;

    > li {
        position: relative;
        border-top: 2px solid transparent;
        text-transform: uppercase;
        min-width: 190px;
        text-align: center;

        &.dropdown {
            &:hover {
                > .dropdown-menu {
                    display: block;
                }
            }
        }

        &.active {
            border-top-color: $navbar-default-bg;

            > a {
                border-top: none;
                border-bottom: none;
                margin-right: 0;
                cursor: pointer !important;

                &:hover {
                    border-top: none;
                    border-bottom: none;
                    margin-right: 0;
                    cursor: pointer !important;
                }
            }
        }

        > a {
            position: relative;
            border: 0;

            &:after {
                content: '';
                display: block;
                width: 1px;
                background-color: $panel-bg;
                position: absolute;
                top: $padding-small-horizontal;
                bottom: $padding-small-horizontal;
                right: 0;
            }

            .icon {
                margin-right: $padding-base-vertical;
            }

            .k-icon {
                background-position: 0px -32px;
            }
        }

        .dropdown-menu {
            display: none;
            padding: 0;
            width: 100%;
            position: absolute;
            background-color: #e9e9e9;
            list-style: none;
            z-index: 100;

            > li {
                > a {
                    position: relative;
                    display: block;
                    padding: 10px 15px;
                    text-align: left;

                    &:after {
                        content: '';
                        display: block;
                        height: 1px;
                        background-color: $panel-bg;
                        position: absolute;
                        left: $padding-small-horizontal;
                        right: $padding-small-horizontal;
                        top: 0;
                    }

                    &:hover {
                        text-decoration: none;
                    }

                    .icon {
                        margin-right: $padding-base-vertical;
                    }
                }

                &.active {
                    > a {
                        background-color: $panel-bg;
                    }
                }
            }
        }
    }
}
