/*
 * default.scss
 * Import all the SASS partials you wish to use on this stylesheet
 */

/* Custom project variables */
@import "config/custom-variables";

/* Bootstrap */
@import "config/bootstrap-variables";
@import "config/bootstrap-partials";

/* Functions */

/* Mixins */

/* Utils */

/* Components */
@import "components/k-grid";
@import "components/_theme.scss";
@import "components/navbar";
@import "components/footer";
@import "components/btn";
@import "components/alert";
@import "components/branding";
@import "components/nav-tabs";
@import "components/icons";
@import "components/toggle";
@import "components/map";

/* Pages */
@import "pages/global";
@import "pages/login";
@import "pages/tools";
@import "pages/my-profile";
@import "pages/trial-register";
@import "pages/register";
@import "pages/billing";

/* Resolution Specific */
@import "components/handheld";