﻿.btn {
	text-transform: uppercase;
}

.btn-lg {
	min-width: 200px;
}

.btn-primary {
    background-color: $brand-color;
    border-color: darken($brand-color, 5%);

    &:hover,
    &:focus,
    &:focus:not(.l-stat-disabled):not([disabled]) {
        background-color: darken($brand-color, 5%);
        border-color: darken($brand-color, 10%);
    }
}

.k-grid-update,
.btn-success {
    background-color: $brand-success;
    border-color: darken($brand-success, 5%);

    &:hover,
    &:focus,
    &:focus:not(.l-stat-disabled):not([disabled]) {
        background-color: darken($brand-success, 5%);
        border-color: darken($brand-success, 10%);
    }
}

.k-grid-cancel,
.btn-danger {
    background-color: $brand-error;
    border-color: darken($brand-error, 5%);

    &:hover,
    &:focus,
    &:focus:not(.l-stat-disabled):not([disabled]) {
        background-color: darken($brand-error, 5%);
        border-color: darken($brand-error, 10%);
    }
}